<template>
  <!--- 우측 패널 --->
  <section>
    <!-- overlay -->
    <v-overlay absolute="absolute" :value="tabOverlay">
      <v-progress-circular
        indeterminate
        size="50"
        color="deep-orange lighten-2"
      ></v-progress-circular>
    </v-overlay>
    <div
      v-if="tabItems.length > 1"
      :style="`height: ${tableHeight}px; padding-top: 3px`"
      class="b-t1"
    >
      <v-tabs v-model="tab">
        <v-tab v-for="item in tabItems" :key="item.title">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabItems" :key="item.title">
          <v-simple-table
            class="simple-table"
            dense
            :fixed-header="item.height ? true : false"
            :height="item.height ? item.height : ''"
          >
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="item in item.headers"
                  :style="`width: ${item.width};`"
                  :key="item.text"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <!--key: object key, item: object value) -->
              <tr
                v-for="(item, key) in item.selectedItem"
                :key="key"
                v-show="item.isHidden ? false : true"
              >
                <td>{{ item.name }}</td>
                <template v-if="item.isCustom">
                  <slot :name="`${key}_tab_custom`" :item="item"></slot>
                </template>
                <template v-else>
                  <!-- 빈칸 '-' 표시 (null & undefined & empty) -->
                  <td v-html="item.value || (item.value === 0 ? 0 : '-')"></td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
          <slot :name="`${item.id}_bottom_button`"></slot>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- tab이 1개일 경우 -->
    <div v-else :style="`height: ${tableHeight}px; `">
      <v-simple-table
        dense
        class="simple-table"
        :fixed-header="true"
        :style="`max-height: ${tableHeight}px; overflow-y:auto;`"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left"
                v-for="item in $props.tabItems[0].headers"
                :style="`width: ${item.width};`"
                :key="item.text"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!--value: object value, name: object Key) -->
            <tr
              v-for="(value, key) in tabItems[0].selectedItem"
              :key="key"
              v-show="value.isHidden ? false : true"
            >
              <td>{{ value.name }}</td>
              <template v-if="value.isCustom">
                <slot :name="`${key}_tab_custom`" :item="value"></slot>
              </template>
              <template v-else>
                <!-- 빈칸 '-' 표시 (null & undefined & empty) -->
                <td v-html="value.value || (value.value === 0 ? 0 : '-')"></td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <slot :name="`${tabItems[0].id}_bottom_button`"></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabItems: {
      type: Array,
      required: true,
    },
    tableHeight: {
      type: String,
      required: true,
    },
    tabOverlay: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tab: null,
  }),
};
</script>
