<template>
  <div class="panel-contents">
    <!-- 좌측 패널 -->
    <div :class="`col-${leftPanelWidth}`">
      <div class="panel">
        <h4 class="panel-top-title">{{ leftPanelTitle }}</h4>
        <slot name="leftPanel" />
      </div>
    </div>
    <!-- 우측 패널 -->
    <div :class="`col-${rightPanelWidth}`">
      <div class="panel">
        <h4 class="panel-top-title">{{ rightPanelTitle }}</h4>
        <slot name="rightPanel" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftPanelWidth: {
      type: String,
      default: '1050',
    },
    rightPanelWidth: {
      type: String,
      default: '337',
    },
    leftPanelTitle: {
      type: String,
      required: true,
    },
    rightPanelTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
